import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NonStretchedImage from './NonStretchedImage'
import { TwoGrid } from './Grid'

const HeroWrap = styled.div`
	position: relative;
	width: 100%;
	padding: 130px 0 130px;
	background: #F6F9F9;
	& label {
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		color: #6F8D9E;
	}
	& h1 {
		width: 100%;
		margin: 10px 0;
		margin-left: -4px;
		color: #122D3D;
	}
	@media(max-width: 1024px){
		& h1 {
			font-size: 40px;
		}
	}
	@media(max-width: 768px){
		padding: 80px 0;

	}
`

class ContentHero extends React.Component {
	render() {
		const { data } = this.props
		return(
			<HeroWrap>
				<section>
					<div className="container">
						<label>{data.subtitle}</label>
						<h1>{data.title}</h1>
					</div>
				</section>
			</HeroWrap>
		)
	}
}

export default ContentHero